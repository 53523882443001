import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
  <Seo title="Brett Montaigne" />
  <h2>Welcome</h2>
  <p>I'm a software engineer with interests in AI and distributed systems.</p>
  <ul>
    <li><Link to="https://www.linkedin.com/in/brettmontaigne/">LinkedIn</Link></li>
    <li><Link to="https://www.twitter.com/brettmontaigne/">Twitter</Link></li>
    <li><Link to="https://github.com/BrettMontaigne">GitHub</Link></li>
  </ul>
  <br></br>
  <hr></hr>
</Layout>
)

export default IndexPage
